import { useState } from 'react'
import TweenOne from 'rc-tween-one'
import { Row, Col, Tabs, List, Avatar } from 'antd'
import { PushpinOutlined } from '@ant-design/icons'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IHomeComponent } from "./types"
import { getChildrenToRender } from './utils'

import Website from '../../assets/images/website.svg'

import TableSelect from '../../assets/images/TableSelect.png'

const TabPane = Tabs.TabPane

const Portfolio : React.FC<IHomeComponent> = ({dataSource, isMobile, ...rest }) => {
  const [current, setCurrent] = useState<number>(1)
  const [activeImage, setActiveImage] = useState<any>(TableSelect)

  const onChange = (key: string) => {
    setCurrent(parseFloat(key))
  }

  const changeActiveImage = (item: any) => {
    setActiveImage(item.img)
  }

  const getBlockChildren = (item: any, i: number) => {
    const { tag, content } = item
    const { text, img } = content
    const childrenList = text.childrenList
    const { icon } = tag
    const tagText = tag.text
    
    return (
      <TabPane
        key={i + 1}
        tab={
          <div className={tag.className}>
            <PushpinOutlined />
            <div {...tagText}>{tagText.children}</div>
          </div>
        }
        className={item.className}
      >
        <TweenOne.TweenOneGroup
          enter={{
            y: 30,
            delay: 300,
            opacity: 0,
            type: 'from',
            ease: 'easeOutQuad',
          }}
          leave={undefined}
          component=""
        >
          {current === i + 1 && (
            <Row
              key="content"
              className={content.className}
              gutter={content.gutter}

            >
              <Col className={text.className} xs={text.xs} md={text.md}>
                <List
                  itemLayout="horizontal"
                  dataSource={childrenList}
                  renderItem={(item: any) => (
                    <List.Item className="pressable" onClick={() => changeActiveImage(item)}>
                      <List.Item.Meta
                        avatar={<Avatar src={icon.children === 'web' ? Website : item.icon} shape="square" />}
                        title={<span onClick={() => console.log(item)}>{item.title}</span>}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Col>
              <Col className={img.className} xs={img.xs} md={img.md}>
                <img src={current === 1 ? activeImage : img.children} width="100%" alt={item.title} />
              </Col>
            </Row>
          )}
        </TweenOne.TweenOneGroup>
      </TabPane>
    )
  }

  const tabsChildren = dataSource.block.children.map(getBlockChildren)

  return (
    <div {...rest} {...dataSource.wrapper}>
      <div {...dataSource.page}>
        <div {...dataSource.titleWrapper}>
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
        </div>

        <OverPack {...dataSource.OverPack}>
          <TweenOne.TweenOneGroup
            key="tabs"
            enter={{
              y: 30,
              opacity: 0,
              delay: 200,
              type: 'from',
            }}
            leave={{ y: 30, opacity: 0 }}
            {...dataSource.tabsWrapper}
          >
            <div>
              <Tabs
                key="tabs"
                onChange={onChange}
                activeKey={`${current}`}
                {...dataSource.block}
              >
                {tabsChildren}
              </Tabs>
            </div>
          </TweenOne.TweenOneGroup>
        </OverPack>
      </div>
    </div>
  )
}

export default Portfolio