import React, { useState } from 'react'
import TweenOne from 'rc-tween-one'
import { Menu } from 'antd'
import { Link as RSLink } from 'react-scroll'
import { getChildrenToRender } from './utils'
import { IHomeComponent } from './types'
import { Link } from 'react-router-dom'

const { Item, SubMenu } = Menu

const Header: React.FC<IHomeComponent> = ({dataSource, isMobile, ...rest }) => {
  const [phoneOpen, openPhone] = useState<any>()
  const [activeMenu, setActiveMenu] = useState<any>('home')

  const isAbout = dataSource.isAbout
  const navData = dataSource.Menu.children
  const navAboutData = dataSource.AboutMenu.children
  const moment = phoneOpen === undefined ? 300 : null

  const phoneClick = () => {
    openPhone(!phoneOpen)
  }

  const handleSetActive = (to: string) => {
    setActiveMenu(to)
  }

  const navChildren = navData.map((item: any) => {
    const { children: a, subItem, ...itemProps } = item
    if (subItem) {
      return (
        <SubMenu
          key={item.name}
          {...itemProps}
          title={
            <div
              {...a}
              className={`header3-item-block ${a.className}`.trim()}
            >
              {a.children.map(getChildrenToRender)}
            </div>
          }
          popupClassName="header3-item-child"
        >
          {subItem.map(($item: any, ii: number) => {
            const { children: childItem } = $item
            const child = childItem.href ? (
              <RSLink {...childItem} activeClass="active" to={childItem.href} spy={true} smooth={true} offset={50} duration={500} onSetActive={handleSetActive}>
                {childItem.children.map(getChildrenToRender)}
              </RSLink>
            ) : (
              <div {...childItem}>
                {childItem.children.map(getChildrenToRender)}
              </div>
            )
            return (
              <Item key={$item.name || ii.toString()} {...$item}>
                {child}
              </Item>
            )
          })}
        </SubMenu>
      )
    }
    console.log(item.children.href)
    return (
      <Item key={item.name} {...itemProps} className={activeMenu === item.children.href ? 'ant-menu-item-selected' : ''}>
        {item.children.href === 'https://blog.iqra.co.id' ? (
          <Link href={item.children.href} to={{ pathname: item.children.href}} target="_blank">
            {a.children.map(getChildrenToRender)}
          </Link>
        ) : (
          <RSLink {...a} className={`header3-item-block`.trim()} activeClass="active" to={item.children.href} spy={true} smooth={true} offset={10} duration={500} onSetActive={handleSetActive}>
            {a.children.map(getChildrenToRender)}
          </RSLink>
        )}
      </Item>
    )
  })

  const navAboutChildren = navAboutData.map((item: any) => {
    const { children: a, subItem, ...itemProps } = item
    if (subItem) {
      return (
        <SubMenu
          key={item.name}
          {...itemProps}
          title={
            <div
              {...a}
              className={`header3-item-block ${a.className}`.trim()}
            >
              {a.children.map(getChildrenToRender)}
            </div>
          }
          popupClassName="header3-item-child"
        >
          {subItem.map(($item: any, ii: number) => {
            const { children: childItem } = $item
            const child = childItem.href ? (
              <Link {...childItem} to={childItem.href}>
                {childItem.children.map(getChildrenToRender)}
              </Link>
            ) : (
              <div {...childItem}>
                {childItem.children.map(getChildrenToRender)}
              </div>
            )
            return (
              <Item key={$item.name || ii.toString()} {...$item}>
                {child}
              </Item>
            )
          })}
        </SubMenu>
      )
    }

    return (
      <Item key={item.name} {...itemProps} className={window.location.href.indexOf(item.children.href) !== -1 ? 'ant-menu-item-selected' : ''}>
        <Link {...a} className={`header3-item-block`.trim()} to={item.children.href}>
          {a.children.map(getChildrenToRender)}
        </Link>
      </Item>
    )
  })

  return (
    <TweenOne
      component="header"
      animation={{ opacity: 0, type: 'from' }}
      {...dataSource.wrapper}
      {...rest}
    >
      <div
        {...dataSource.page}
        className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
      >
        <TweenOne
          animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
          {...dataSource.logo}
        >
          <img width="100%" src={dataSource.logo.children} alt="IQRA" />
        </TweenOne>
        {isMobile && (
          <div
            {...dataSource.mobileMenu}
            onClick={() => {
              phoneClick()
            }}
          >
            <em />
            <em />
            <em />
          </div>
        )}
        <TweenOne
          {...dataSource.Menu}
          animation={
            isMobile
              ? {
                  x: 0,
                  height: 0,
                  duration: 300,
                  onComplete: (e) => {
                    if (phoneOpen) {
                      e.target.style.height = 'auto'
                    }
                  },
                  ease: 'easeInOutQuad',
                }
              : null
          }
          moment={moment}
          reverse={!!phoneOpen}
        >
          <Menu
            mode={isMobile ? 'inline' : 'horizontal'}
            theme="light"
          >
            {isAbout ? navAboutChildren : navChildren}
          </Menu>
        </TweenOne>
      </div>
    </TweenOne>
  )
}

export default Header
