// import QueueAnim from 'rc-queue-anim'
import { Row } from 'antd'
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IHomeComponent } from "./types"

const Feature1 : React.FC<IHomeComponent> = ({dataSource, isMobile, ...rest }) => {
  const features: any[] = dataSource.data;

  return (
    <div {...rest} {...dataSource.wrapper}>
      {dataSource.title && (
        <h1 className='top-title'>{dataSource.title}</h1>
      )}
      {features.map((item, index) => (
        <Row key={index} style={{
          flexDirection: item.type === 'right' ? 'row-reverse' : 'row',
        }}>
          <div {...item.img.textWrapper}>
            <img src={item.img.children} width="100%" alt="IQRA" />
            <div className='feature1-overlay'>
              {item.thumbs && item.thumbs.map((item: any, index: number) => (
                <div key={index} {...item.textWrapper}>
                  <div className="item-col">
                    <div className='img-wrapper'>
                      <img src={item.img} width="100%" alt="IQRA" />
                    </div>
                    <span>
                      {item.text}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div {...item.text.textWrapper}>
            {/* <OverPack> */}
              {/* <QueueAnim
                key="text"
                type={'right'}
                leaveReverse
                ease={['easeOutQuad', 'easeInQuad']}
                className="feature1-text-content"
              > */}
              <div key="text" className="feature1-text-content">
                <h1 key="h1">
                  {item.text.title}
                </h1>
                <div key="description" className="feature1-description">
                  {item.text.description}
                </div>
              </div>
              {/* </QueueAnim> */}
            {/* </OverPack> */}
          </div>
        </Row>
      ))}
    </div>
  )
}

export default Feature1