import TGP from '../../assets/images/TGP.png'
import Logo from '../../assets/images/IQRA_Web.png'
import TopBanner from '../../assets/images/TGP.png'
import Mail from '../../assets/images/mail.svg'
import Phone from '../../assets/images/phone.svg'
import Whatsapp from '../../assets/images/whatsapp.svg'

import Project from '../../assets/images/dev.svg'
import Android from '../../assets/images/android.svg'
import Apple from '../../assets/images/apple.svg'
import Shield from '../../assets/images/shield.svg'
import Cloud from '../../assets/images/cloud-computing.svg'
import Microsoft from '../../assets/images/microsoft.svg'

import Target from '../../assets/images/target.svg'
import UX from '../../assets/images/ui.svg'
import Design from '../../assets/images/design.svg'
import Development from '../../assets/images/development.svg'
import CheckList from '../../assets/images/checklist.svg'
import Rocket from '../../assets/images/rocket.svg'

import TableSelect from '../../assets/images/TableSelect.png'
import TableSelectIcon from '../../assets/images/TableSelect_icon.png'
import Salah from '../../assets/images/Salah.png'
import SalahIcon from '../../assets/images/Salah_icon.png'
import Tentorium from '../../assets/images/tentorium-dark.png'
import TentoriumIcon from '../../assets/images/tentorium-1024.png'

import WebDev from '../../assets/images/web-dev.jpg'

import Feature1Img1 from '../../assets/images/who-we-are.png'
import Feature1Img2 from '../../assets/images/feature-1/2.jpeg'
// import Feature1Img3 from '../../assets/images/feature-1/padel.png'
import Feature1Img5 from '../../assets/images/feature-1/sustainable.png'

import Feature2Img1 from '../../assets/images/feature-2/1.jpeg'

import SolutionImg1 from '../../assets/images/solutions/1.png'
import SolutionImg2 from '../../assets/images/solutions/2.png'
import SolutionImg3 from '../../assets/images/solutions/3.png'
import SolutionImg4 from '../../assets/images/solutions/4.png'

import Padel from '../../assets/images/padel.png'
import LocationImg from '../../assets/images/upcoming.png'
// import ProblemImg from '../../assets/images/problem.png'
import CommImg from '../../assets/images/community.png'
import AcademyImg from '../../assets/images/acdmy.png'

import IndoorImg from '../../assets/images/indoor.png'
import OutdoorImg from '../../assets/images/outdoor.png'

import FacilityImg1 from '../../assets/images/facilities/1.png'
import FacilityImg2 from '../../assets/images/facilities/2.png'
import FacilityImg3 from '../../assets/images/facilities/3.png'
import FacilityImg4 from '../../assets/images/facilities/4.png'
import FacilityImg5 from '../../assets/images/facilities/5.png'
import FacilityImg6 from '../../assets/images/facilities/6.png'

import TeamImg1 from '../../assets/images/teams/1.png'
import TeamImg2 from '../../assets/images/teams/2.png'
import TeamImg3 from '../../assets/images/teams/3.jpeg'
import TeamImg4 from '../../assets/images/teams/4.png'

export const HeaderNavDataSource = (isMobile: boolean, headerClassName: string, isAbout?: boolean) => {
  return {
    wrapper: { className: `header3 home-page-wrapper ${headerClassName || ''} ${isMobile ? 'mobile-enabled' : ''}` },
    page: { className: 'home-page' },
    logo: {
      className: 'header3-logo',
      children: Logo,
    },
    isAbout: isAbout,
    Menu: {
      className: 'header3-menu',
      children: [
        {
          name: 'home',
          className: 'header3-item',
          children: {
            href: 'home',
            children: [{ children: 'Home', name: 'text' }],
          },
        },
        {
          name: 'who-we-are',
          className: 'header3-item',
          children: {
            href: 'who-we-are',
            children: [{ children: 'Who We Are', name: 'text' }],
          },
        },
        {
          name: 'what-we-do',
          className: 'header3-item',
          children: {
            href: 'what-we-do',
            children: [{ children: 'What We Do', name: 'text' }],
          },
        },
        {
          name: 'how-we-work',
          className: 'header3-item',
          children: {
            href: 'how-we-work',
            children: [{ children: 'How We Work', name: 'text' }],
          },
        },
        {
          name: 'portfolio',
          className: 'header3-item',
          children: {
            href: 'portfolio',
            children: [{ children: 'Portfolio', name: 'text' }],
          },
        },
        {
          name: 'contact',
          className: 'header3-item',
          children: {
            href: 'contact',
            children: [{ children: 'Contact', name: 'text' }],
          },
        },
        {
          name: 'blog',
          className: 'header3-item',
          children: {
            href: 'https://blog.iqra.co.id',
            children: [{ children: 'Blog', name: 'text' }],
          },
        },
      ],
    },
    AboutMenu: {
      className: 'header3-menu',
      children: [
        {
          name: 'home',
          className: 'header3-item',
          children: {
            href: '/home',
            children: [{ children: 'Home', name: 'text' }],
          },
        },
        {
          name: 'about',
          className: 'header3-item',
          children: {
            href: '/about',
            children: [{ children: 'About', name: 'text' }],
          },
        },
      ],
    },
    mobileMenu: { className: 'header3-mobile-menu' },
  }
}

export const BannerDataSource = {
  wrapper: {
    id: 'home',
    className: 'home-page-wrapper banner5 element'
  },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      // { name: 'title', children: 'IQRA', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        // children: 'TheGreenPadel',
        children: <div>IQRA<span className="banner5-green">TECHNOLOGY</span></div>
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: 'Where Technology Meets Creativity',
      },
      // {
      //   name: 'button',
      //   className: 'banner5-button-wrapper',
      //   children: {
      //     href: 'who-we-are',
      //     className: 'banner5-button',
      //     type: 'primary',
      //     children: 'Learn More',
      //   },
      // },
    ],
  },
  image: {
    className: 'banner5-image',
    children: TopBanner,
  },
}
export const SolutionDataSource = {
  wrapper: {
    id: 'solution',
    className: 'home-page-wrapper content0-wrapper element'
  },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.01, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        className: 'white',
        children: (
          <>The<span className='green'>Green</span>Padel</>
        )
      },
      {
        name: 'subtitle',
        className: 'subtitle',
        children: 'TheGreenPadel aims to become the biggest premium sustainable padel community in South East Asia'
      }
    ],
  },
  childWrapper: {
    className: 'ant-row content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: SolutionImg1,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Top professionals & premium facilities',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: SolutionImg2,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Leading Academy in South East Asia',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: SolutionImg3,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Community driven sports clubs',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: SolutionImg4,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Profitable Sustainability',
            },
          ],
        },
      },
    ],
  },
}
export const OurTeamDataSource = {
  wrapper: {
    id: 'our-team',
    className: 'home-page-wrapper content0-wrapper element'
  },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.01, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: <span className="title-span">The <span className="green">Green</span> Team</span>}],
  },
  childWrapper: {
    className: 'ant-row content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-img',
              children: TeamImg1,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'PABLO GIRÁLDEZ',
            },
            {
              name: 'subtitle',
              className: 'content0-block-subtitle',
              children: `Partnerships and Operations`,
            },
          ],
        },
      },
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-img',
              children: TeamImg2,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'MARCO HERNÁIZ',
            },
            {
              name: 'subtitle',
              className: 'content0-block-subtitle',
              children: 'Strategy & International Expansion',
            },
          ],
        },
      },
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-img',
              children: TeamImg3,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'ALEX DOMENECH',
            },
            {
              name: 'subtitle',
              className: 'content0-block-subtitle',
              children: 'General Manager & Coach',
            },
          ],
        },
      },
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 12,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-img',
              children: TeamImg4,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'DIEGO CABALLERO',
            },
            {
              name: 'subtitle',
              className: 'content0-block-subtitle',
              children: 'Food & Beverages Manager',
            },
          ],
        },
      },
    ],
  },
}
export const ServiceDataSource = {
  wrapper: {
    id: 'how-we-work',
    className: 'home-page-wrapper content0-wrapper element'
  },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.01, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'How We Work' }],
  },
  childWrapper: {
    className: 'ant-row content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: Target,
            },
            {
              name: 'title-h4',
              className: 'content0-block-title',
              children: 'Defenitions & Scopes',
            },
            { name: 'content', children: 'Understanding your business, your vision and your expectations is an integral part of the web development process. We work with you to identify the project details and determine the features, functions, content categories, aesthetic requirements, technical implications, timeline and other specifications.' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: UX,
            },
            {
              name: 'title-h4',
              className: 'content0-block-title',
              children: 'Mapping & UX',
            },
            {
              name: 'content',
              children: 'For complex sites, we use wire frames to map out the architecture and flow of your project to ensure that everyone understands how pages and content relate to one another. We will also use this process to identify the nature and source of all the content for the system.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: Design,
            },
            {
              name: 'title-h4',
              className: 'content0-block-title',
              children: 'Design Phase',
            },
            {
              name: 'content',
              children: 'Once the features, functions and other specifications are defined, we move on to the design stage. The time required for this phase will vary depending on the complexity of the project but we keep designing until you are happy with what we produce.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: Development,
            },
            {
              name: 'title-h4',
              className: 'content0-block-title',
              children: 'Development',
            },
            {
              name: 'content',
              children: 'Upon approval of the design, we move on to development of your system. We write code using the best industry practices to produce a clean, fast, and modern system. We also make sure to keep you informed on where we are in relation to the project schedule.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: CheckList,
            },
            {
              name: 'title-h4',
              className: 'content0-block-title',
              children: 'Testing & Bug Fixing',
            },
            {
              name: 'content',
              children: 'We tink and test to ensure that everything is working as required and that your system or application is ready for the worldwide launching.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: Rocket,
            },
            {
              name: 'title-h4',
              className: 'content0-block-title',
              children: 'Deployment & Support',
            },
            {
              name: 'content',
              children: 'We provide free technical support for a period of time and are always available to make additions and improvements, as required. Our developer will help to make sure that your system is kept up-to-date with accurate, fresh, properly organized modules.',
            },
          ],
        },
      },
    ],
  },
}
export const PortfolioDataSource = {
  wrapper: {
    id: 'portfolio',
    className: 'home-page-wrapper content7-wrapper'
  },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper with-subtitle',
    children: [
      {
        name: 'title',
        children: 'Portfolio',
        className: 'title-h1',
      },
      {
        name: 'title-h3',
        className: 'title-h3',
        children: 'Our works from different clients and different platforms'
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { children: 'Mobile Application', className: 'content7-tag-name' },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            childrenList: [
              {title: "Table Select", description: "Restaurant Booking System", icon: TableSelectIcon, img: TableSelect},
              {title: "Salah", description: "Muslim Prayer & Lifestyles", icon: SalahIcon, img: Salah},
              {title: "Tentorium", description: "E-Learning System", icon: TentoriumIcon, img: Tentorium},
            ]
          },
          img: {
            className: 'content7-img',
            children: WebDev,
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'web' },
          text: { className: 'content7-tag-name', children: 'Web Application' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            childrenList: [
              {title: "Doctrina", description: "E-Learning Admin Dashboard"},
              {title: "Arcanum Studio", description: "E-Commerce Website"},
            ]
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children: WebDev,
          },
        },
      },
    ],
  },
}
export const AboutDataSource = {
  wrapper: {
    id: 'about',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1', playScale: 0.01 },
  imgWrapper: { className: 'content1-img', md: 12, xs: 24 },
  img: {
    children: Padel,
  },
  textWrapper: { className: 'content1-text', md: 12, xs: 24 },
  title: { className: 'content1-title', children: 'What is Padel?' },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>Padel is a sport which combines action with fun and social interaction. It’s a great sport for players of all ages and skills, as it is both quick and easy to pick up. Most players get the basics within the first half an hour of playing so that they can enjoy the game.</p>
        <p>Padel is not as dominated by strength, technique and serve as it happens in Tennis and therefore is an ideal game for men, women and youth to compete together. An important skill is match-craft, as points are won rather by strategy than by sheer strength and power.</p>
      </>
    ),
  },
  content1: {
    className: 'content1-content',
    children: (
      <>
        <h1 className="content1-title">The Padel Court</h1>
        <p>Padel is a mix between Tennis and Squash. It’s usually played in doubles on an enclosed court surrounded by walls of glass and metallic mash. The court is one third of the size of a tennis court.</p>
        <p>The ball can bounce of any wall but can only hit the turf once before being returned. Points can be scored when the ball does bounce twice in the competitors’ field.</p>
      </>
    ),
  },
  content2: {
    className: 'content1-content',
    children: (
      <>
        <h1 className="content1-title">Padel Racquet & Ball</h1>
        <p>The game is quick and easy to learn, making it a fun and addictive sport to play. Using a short, stringless padel racquet with an elastic surface with holes and a low compression tennis ball, the service is made underarm.</p>
        <p>Shots are played either before or after the ball bounces off the surrounding glass walls, adding a unique dimension to the sport over conventional tennis.</p>
      </>
    ),
  },
}
export const CommunityDataSource = {
  wrapper: {
    id: 'community',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1', playScale: 0.01 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: TGP,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Community' },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>Padel is an inclusive sport that does not create a distinction based on gender, age, or ethnicity.</p>
        <p>In the Padel community, everyone is welcome!</p>
      </>
    ),
  },
}
export const LocationDataSource = {
  wrapper: {
    id: 'upcoming-location',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1 flex-start', playScale: 0.01 },
  imgWrapper: { className: 'content1-img full-img', md: 14, xs: 24 },
  img: {
    children: LocationImg,
  },
  textWrapper: { className: 'content1-text', md: 10, xs: 24 },
  title: { className: 'content1-title', children: 'Upcoming Location' },
  content: {
    className: 'content1-content',
    children: (
      <ol>
        <li>Indonesia (Jakarta)</li>
        <li>Philippines (Manila)</li>
        <li>Thailand (Bangkok)</li>
        <li>Singapore</li>
        <li>Vietnam (Hanoi)</li>
        <li>Hongkong</li>
      </ol>
    ),
  },
}
export const ClubDataSource = {
  wrapper: {
    id: 'our-clubs',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1', playScale: 0.01 },
  imgWrapper: { className: 'content1-img', md: 12, xs: 24 },
  img: {
    children: Padel,
  },
  textWrapper: { className: 'content1-text', md: 12, xs: 24 },
  title: { className: 'content1-title', children: 'Our First Club: TGP Bali' },
  content: {
    className: 'content1-content',
    children: (
      <>
        <h2 className="content1-subtitle">Why Uluwatu?</h2>
        <p>Bali is a strategic place for its location in the middle of SEA, its worldwide recognition and its life quality. Padel is already booming in Bali, and we have considered it the cradle of TGP to stand out. Pur chosen location is the premium area of Uluwatu.</p>
        <ol className='problem-counter'>
          <li>Sporting culture</li>
          <li>Residential area with the highest growth</li>
          <li>Warm and sunny climate</li>
          <li>Premium touristic area in Bali</li>
          <li>Beautiful landscapes</li>
          <li>Greater infrastructure & access</li>
        </ol>
      </>
    ),
  },
}
export const Feature3DataSource = {
  wrapper: {
    id: 'feature-1',
    className: 'home-page-wrapper feature1-wrapper darker'
  },
  page: { className: 'home-page feature1' },
  data: [
    {
      className: 'feature1-content',
      type: 'left',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: 'Upcoming Location',
        description: (
          <ol>
            <li>Indonesia (Jakarta)</li>
            <li>Philippines (Manila)</li>
            <li>Thailand (Bangkok)</li>
            <li>Singapore</li>
            <li>Vietnam (Hanoi)</li>
            <li>Hongkong</li>
          </ol>
        ),
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: LocationImg,
      },
    },
  ],
}
export const Feature1DataSource = {
  wrapper: {
    id: 'who-we-are',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  data: [
    {
      className: 'feature1-content',
      type: 'right',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: <span className="title-span">Who We Are</span>,
        description: (
          <>
            <p>IQRA Technology is a leading provider of IT consultancy, web development, and mobile development services. Our team of experienced professionals is dedicated to delivering innovative solutions that help our clients stay ahead of the curve in the fast-paced world of technology.</p>
            <p>Whether you're looking to improve your online presence, develop a custom web or mobile application, or need expert guidance on your IT strategy, we're here to help. With a focus on quality, reliability, and exceptional customer service, we've built a reputation as one of the most trusted and respected names in the industry.</p>
            <p>At IQRA Technology, we're committed to helping our clients succeed in the digital age. From initial consultation to final delivery, we work closely with you to ensure that every aspect of your project is completed to the highest standards. So why wait? Contact us today and let us help you achieve your goals!</p>
            {/* <div className="banner5-button-wrapper">
              <a href='/about' className="ant-btn ant-btn-primary banner5-button"><span>Learn more</span></a>
            </div> */}
          </>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: Feature1Img1,
      },
    },
    // {
    //   className: 'feature1-content',
    //   type: 'right',
    //   text: {
    //     textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
    //     title: 'Who we are',
    //     description: (
    //       <>
    //         <p>We are a team of entrepreneurs and Padel professionals & enthusiasts with extensive experience in Asia and the Padel community. We have teamed up and joined forces to analyse the current situation,</p>
    //         <p>and we concluded to a lack of venues and quality, lack of community and</p>
    //         <p>We are on a Mission to change the rules of the game. to carry out an expansion throughout the region to grow this sport expertly.</p>
    //       </>
    //     )
    //   },
    //   img: {
    //     textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
    //     children: Feature1Img2,
    //   },
    // },
    // {
    //   className: 'feature1-content',
    //   type: 'left',
    //   text: {
    //     textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
    //     title: 'Mission',
    //     description: <p>We are on a mission to change the rules of the game. We are carrying an expansion throughout the region to develop Padel creating a large community and premium venues to train and host.</p>
    //   },
    //   img: {
    //     textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
    //     children: Feature1Img3,
    //   },
    // },
    // {
    //   className: 'feature1-content',
    //   type: 'right',
    //   text: {
    //     textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
    //     title: 'Sustainable Essence',
    //     description: <p>We are very aware of the importance of taking care of our present to enjoy a better future. We have developed a system with our sustainable engineers and architects to minimise the electricity consumption and create a zero-waste, single-use-plastic free and</p>
    //   },
    //   img: {
    //     textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
    //     children: Feature1Img5,
    //   },
    // },
  ],
}
export const WhatWeDoDataSource = {
  wrapper: {
    id: 'what-we-do',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  data: [
    {
      className: 'feature1-content',
      type: 'left',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: <span className="title-span">What We Do</span>,
        description: (
          <>
            <p>Our core mission is fueled by our rock-solid passion for padel. We are committed to sharing our love for this dynamic sport with the Southeast Asian community by creating a welcoming and supportive environment for players of all levels to thrive. Our ultimate objective is to establish the region as a leading destination for Padel, and we strive to achieve this by offering top-notch training facilities and fostering a diverse community.</p>
            <p>We are committed to creating a community that embraces diversity and inclusivity and to promoting the growth of Padel across the region.</p>
          </>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: Feature1Img2,
      },
    },
  ],
}
export const FacilitiesDataSource = {
  wrapper: {
    id: 'our-facilities',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  data: [
    {
      className: 'feature1-content',
      type: 'right',
      text: {
        textWrapper: { className: 'feature1-text text-left ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: 'Top Professionals and Premium facilities',
        description: (
          <ul className='square'>
            <li>Strategic locations in SEA</li>
            <li>Indoor climatized venues</li>
            <li>Top-tier coaches</li>
            <li>World Padel Tour courts</li>
            <li>Community areas</li>
            <li>Restaurant & Pool Lounge</li>
          </ul>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: Feature2Img1,
      },
      thumbs: [
        {
          textWrapper: { className: 'feature1-img-thumb ant-col-xs-10 ant-col-md-8', md: 8, xs: 24 },
          img: FacilityImg1,
          text: "Strategic locations in SEA"
        },
        {
          textWrapper: { className: 'feature1-img-thumb ant-col-xs-10 ant-col-md-8', md: 8, xs: 24 },
          img: FacilityImg2,
          text: "Indoor climatized venues"
        },
        {
          textWrapper: { className: 'feature1-img-thumb ant-col-xs-10 ant-col-md-8', md: 8, xs: 24 },
          img: FacilityImg3,
          text: "Top-tier coaches"
        },
        {
          textWrapper: { className: 'feature1-img-thumb ant-col-xs-10 ant-col-md-8', md: 8, xs: 24 },
          img: FacilityImg4,
          text: "World Padel Tour courts"
        },
        {
          textWrapper: { className: 'feature1-img-thumb ant-col-xs-10 ant-col-md-8', md: 8, xs: 24 },
          img: FacilityImg5,
          text: "Community areas"
        },
        {
          textWrapper: { className: 'feature1-img-thumb ant-col-xs-10 ant-col-md-8', md: 8, xs: 24 },
          img: FacilityImg6,
          text: "Restaurant & Pool Lounge"
        },
      ]
    },
  ],
}
export const FeatureProblemDataSource = {
  wrapper: {
    id: 'feature-problem',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  data: [
    // {
    //   className: 'feature1-content',
    //   type: 'left',
    //   text: {
    //     textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
    //     title: 'Problems in South East Asia',
    //     description: (
    //       <ul className='square'>
    //         <li>Outdoor weather affects the  game performance</li>
    //         <li>Lack of expertise and professional coaches</li>
    //         <li>Existing venues limit growth and network effects</li>
    //       </ul>
    //     )
    //   },
    //   img: {
    //     textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
    //     children: ProblemImg,
    //   },
    // },
    {
      className: 'feature1-content',
      type: 'right',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: 'The Green Community',
        description: (
          <>
            <p>Joining our Padel community allows you to connect with like-minded individuals who share your passion for a sport that promotes fairness, respect, and equality for all ages and genders. You'll have access to top-notch facilities, form new relationships, build meaningful connections, and help promote the sport. With a strong and thriving Padel community, you'll enjoy longer playing times and experience the benefits of a supportive and inclusive space that encourages personal growth and success.</p>
            <ol className='problem-counter'>
              <li>Biggest Padel community in Asia</li>
              <li>Local community centers for wellness lifestyle</li>
              <li>All in one venues with stores & services, coworking and accomodation facilities</li>
              <li>Eco friendly Spa wellness retreats, Fitness and Yoga</li>
              <li>Three level of memberships adapted to every customer profile</li>
            </ol>
            <p>Join us and unlock the potential of a welcoming, diverse, and thriving Padel community.</p>
          </>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: CommImg,
      },
    },
  ],
}
export const FeatureAcademyDataSource = {
  wrapper: {
    id: 'academy',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  data: [
    {
      className: 'feature1-content',
      type: 'left',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: 'TGP Academy',
        description: (
          <>
            <p>In Padel, having access to a quality academy and a great coach is crucial for players to improve their skills and achieve their goals. The academy provides a focused environment for players to learn, train, and compete while the coach provides guidance, feedback, and support to help players reach their full potential. Our academy offers:</p>
            <ul className='square'>
              <li>All-day-round classes for kids and adults</li>
              <li>Top-tier Spanish coaches and methodology</li>
              <li>Courses to certificate regional padel coaches</li>
              <li>Coaching top Professional teams in the region</li>
              <li>TGP training Video Channel & competitions live streaming</li>
              <li>Social tournaments, amateur ranking and international competitions</li>
              <li>International Training camps in Bali</li>
              <li>Personalized training adapted to all levels</li>
            </ul>
            <p>Together, we want to help players elevate their game and reach new heights in the exciting world of Padel.</p>
          </>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: AcademyImg,
      },
    },
  ],
}
export const SustainabilityDataSource = {
  wrapper: {
    id: 'academy',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  data: [
    {
      className: 'feature1-content',
      type: 'left',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: 'Building A Sustainable Path',
        // description: (
        //   <ul className='square'>
        //     <li>Energy Efficiency Cost Savings</li>
        //     <li>Sustainability Sponsorships</li>
        //     <li>Powerful Branding and Free Marketing</li>
        //   </ul>
        // )
        description: (
          <>
            <p>We are very aware of the importance of taking care of our present to enjoy a better future. Maintaining a continuous eco-centric approach, we have developed a system with our sustainable engineers and architects, to minimise electricity consumption and create a zero-waste, single-use-plastic free venues.</p>
            <p>Our main source of electricity is powered by solar panels and heaters. We have a rainwater collection system that is filtered and serves as a water supply for baths and showers. In turn, this water self-heats with the heat released from the ventilation system. We also have heat-reflecting solar crystals, radiant heat paint and our own ventilation system.</p>
          </>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: Feature1Img5,
      },
    },
  ],
}
export const OurServicesDataSource = {
  wrapper: {
    id: 'portfolio',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  data: [
    {
      className: 'feature1-content',
      type: 'right',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: 'Our Services',
        description: (
          <ol className='problem-counter'>
            <li>Consultancy for new padel clubs</li>
            <li>Padel clubs management & operations</li>
            <li>Importing and distribution of courts and padel equipment</li>
            <li>Organization of the Asian Padel Tour</li>
          </ol>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: Padel,
      },
    },
  ],
}
export const BaliFacilitiesDataSource = {
  wrapper: {
    id: 'academy',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  title: 'Our Facilities',
  data: [
    {
      className: 'feature1-content',
      type: 'left',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: 'Indoor',
        description: (
          <ul className='square'>
            <li>7 padel courts climatized</li>
            <li>Sports Clinic</li>
            <li>Gymnasium</li>
            <li>Bar & Restaurant</li>
            <li>Co-working cafe</li>
            <li>Changing rooms</li>
            <li>CRE Stores/services</li>
            <li>Multipurpose rooms</li>
            <li>Kids daycare</li>
          </ul>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: IndoorImg,
      },
    },
    {
      className: 'feature1-content',
      type: 'right',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: 'Outdoor',
        description: (
          <ul className='square'>
            <li>3 Padel courts roofed</li>
            <li>Lobby + Padel store</li>
            <li>Outdoor Restaurant are</li>
            <li>Pool lounge area</li>
            <li>Spa, Sauna & Icetub</li>
            <li>Changing rooms</li>
            <li>Massage rooms</li>
            <li>Kids outdoor area</li>
            <li>Parking (100 pax)</li>
          </ul>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: OutdoorImg,
      },
    },
  ],
}
export const UpcomingLocationDataSource = {
  wrapper: {
    id: 'academy',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  data: [
    {
      className: 'feature1-content',
      type: 'left',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: 'Upcoming Location',
        description: (
          <ol className='problem-counter'>
            <li>Jakarta</li>
            <li>Japan</li>
            <li>Philippines</li>
            <li>Thailand</li>
            <li>Singapore</li>
            <li>Vietnam</li>
          </ol>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: LocationImg,
      },
    },
  ],
}
export const Feature0DataSource = {
  wrapper: { id: 'what-we-do', className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper with-subtitle',
    children: [
      {
        name: 'title',
        children: 'What We Do',
        className: 'title-h1',
      },
      {
        name: 'title-h3',
        className: 'title-h3',
        children: 'If you have a problem that needed to be solved, do not hesitate to get in touch with us. Our main services are listed below.',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Project,
          },
          textWrapper: { className: 'content3-text' },
          title: {name: 'title-h3', className: 'content3-title secondary', children: 'Software Development' },
          content: {
            className: 'content3-content',
            children:
              'We build both Native and Web-based systems using modern programming languages.',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Android,
          },
          textWrapper: { className: 'content3-text' },
          title: {
            name: 'title-h3',
            className: 'content3-title secondary',
            children: 'Android App Development'
          },
          content: {
            className: 'content3-content',
            children:
              'We build Android application using modern programming languages, Objective-C, React Native, etc.',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Apple,
          },
          textWrapper: { className: 'content3-text' },
          title: {name: 'title-h3', className: 'content3-title secondary', children: 'iOS App Development' },
          content: {
            className: 'content3-content',
            children:
              'We build iOS application using modern programming languages, Objective-C, React Native, etc.',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Shield,
          },
          textWrapper: { className: 'content3-text' },
          title: {name: 'title-h3', className: 'content3-title secondary', children: 'Security Audits' },
          content: {
            className: 'content3-content',
            children:
              'We identify potential security risks in IT systems and helps businesses protect their assets.',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Cloud,
          },
          textWrapper: { className: 'content3-text' },
          title: {name: 'title-h3', className: 'content3-title secondary', children: 'Cloud Computing Solutions' },
          content: {
            className: 'content3-content',
            children:
              'We help clients to meet their goal and secure systems by using modern solutions such as, AWS, Azure, and GCP.',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Microsoft,
          },
          textWrapper: { className: 'content3-text' },
          title: {name: 'title-h3', className: 'content3-title secondary', children: 'Microsoft 365' },
          content: {
            className: 'content3-content',
            children:
              'We build Add-Ins for Microsoft Word, Excel, Powerpoint, and Outlook and integrate it with your system.',
          },
        },
      },
    ],
  },
}
export const ContactDataSource = {
  wrapper: {
    id: 'contact',
    className: 'home-page-wrapper content3-wrapper'
  },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.01 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'Contact Us',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: 'If you have a problem that needed to be solved, do not hesitate to get in touch with us. Contact us using form below;',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Project,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Software Development' },
          content: {
            className: 'content3-content',
            children:
              'We build both Native and Web-based systems using modern programming languages.',
          },
        },
      },
    ],
  },
}
export const FooterDataSource = {
  wrapper: { className: 'home-page-wrapper footer2-wrapper' },
  OverPack: { className: 'home-page footer2', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: [
      {
        name: 'copyright',
        children: 'Copyright © 2023 All rights reserved.',
        className: 'copyright-text',
      },
    ],
  },
  links: {
    className: 'links',
    children: [
      {
        name: 'phone',
        href: 'tel:+34606137234',
        title: 'Call Us',
        className: 'links-phone',
        children: Phone
      },
      {
        name: 'whatsapp',
        href: 'https://wa.me/6281362928575',
        title: 'Whatsapp Us',
        className: 'links-whatsapp',
        children: Whatsapp
      },
      {
        name: 'mail',
        href: 'mailto:hi@iqra.co.id',
        title: 'Email Us',
        className: 'links-mail',
        children: Mail
      },
    ],
  },
}
