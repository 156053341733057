import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { Col, Row, Typography } from 'antd'
import IQRALandscape from '../../assets/images/IQRA-web.png'
import LinkedInIcon from '../Icons/LinkedIn'
import MailIcon from '../Icons/Mail'
import {Link as RSLink} from 'react-scroll'

const { Text, Link } = Typography

const PreFooter: React.FC<any> = ({isMobile, ...rest }) => {
  const wrapper = { id: 'contact', className: 'home-page-wrapper pre-footer-wrapper' }
  const overPack = { className: 'home-page pre-footer', playScale: 0.05 }

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <TweenOne
          animation={{ x: '+=30', opacity: 0, type: 'from' }}
          key="copyright"
          className="footer-copyright"
        >
          <Row className='footer-top-desc'>
            <Col sm={24}>
              <div className='title-wrapper'>
                <h2>WE LOVE TO HEAR FROM YOU</h2>
              </div>
              <div className='pre-description'>
                <p>If you have a problem that needed to be solved, do not hesitate to get in touch with us. Contact us using Whatsapp plugin on your bottom right of the browser window.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={24}>
              <img width={250} src={IQRALandscape} alt="TheGreenPadel" />
              <div className='footer-column'>
                <Text className='label'>Address</Text>
                <Text>Lavita Residence, Kav. 4, Kabupaten Bogor, Jawa Barat</Text>
              </div>
              <div className='footer-column'>
                <Text className='label'>Contact Us</Text>
                <Text>hi@iqra.co.id</Text>
                <Link href='https://wa.me/6282273138788'>+6282273138788 (Whatsapp)</Link>
              </div>
            </Col>
            <Col  md={12} sm={24}>
              <div className='footer-column with-m-top'>
                <RSLink to='home'>Home</RSLink>
                <RSLink to='who-we-are'>Who We Are</RSLink>
                <RSLink to='what-we-do'>What We Do</RSLink>
                <RSLink to='how-we-work'>How We Work</RSLink>
                <RSLink to='portfolio'>Portfolio</RSLink>
              </div>
              <div className='footer-column'>
                <div className='links'>
                  <Link href='mailto:hi@iqra.co.id' target="_blank"><MailIcon /></Link>
                  <Link href='https://www.linkedin.com/company/iqra-tech/' target="_blank"><LinkedInIcon /></Link>
                </div>
              </div>
            </Col>
          </Row>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default PreFooter