import { useEffect, useState } from 'react'
import { enquireScreen } from 'enquire-js'
import { BannerDataSource, FooterDataSource, HeaderNavDataSource, Feature1DataSource, Feature0DataSource, ServiceDataSource, PortfolioDataSource } from '../components/Home/data.source'
import Header from '../components/Home/Header'

import '../components/Home/less/antMotionStyle.less'
import Banner from '../components/Home/Banner'
import Footer from '../components/Home/Footer'
import Service from '../components/Home/Service'
import Feature1 from '../components/Home/Feature1'
import Loader from '../components/Home/Loader'
import PreFooter from '../components/Home/PreFooter'

import TGPOutdoor from '../assets/videos/iqra_tech.mov'
import Feature from '../components/Home/Feature'
import Portfolio from '../components/Home/Portfolio'

let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const Home = () => {
  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)
  const [headerClassName, setHeaderClassName] = useState('')

  useEffect(() => {
    const handleScroll = (headerClassName: string) => {
      if (headerClassName !== 'menu-with-bg' && window.pageYOffset >= 150) {
          setHeaderClassName('menu-with-bg');
      } else if (headerClassName === 'menu-with-bg' && window.pageYOffset < 150) {
          setHeaderClassName('');
      }
    }

    window.onscroll = () => handleScroll(headerClassName);

    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 100)
    } else {
      isShown(true)
    }
  }, [headerClassName, isMobile, show])

  const children = [
    <Header
      dataSource={HeaderNavDataSource(isMobile, headerClassName)}
      isMobile={isMobile}
      key="Header"
    />,
    <Banner
      dataSource={BannerDataSource}
      isMobile={isMobile}
      video={TGPOutdoor}
      key="Banner"
    />,
    <Feature1
      dataSource={Feature1DataSource}
      isMobile={isMobile}
      key="Home"
    />,
    <Feature
      dataSource={Feature0DataSource}
      isMobile={isMobile}
      key="WhatWeDo"
    />,
    <Service
      dataSource={ServiceDataSource}
      isMobile={isMobile}
      key="Service"
    />,
    <Portfolio
      dataSource={PortfolioDataSource}
      isMobile={isMobile}
      key="Portfolio"
    />,
    // <About
    //   dataSource={AboutDataSource}
    //   isMobile={isMobile}
    //   id="About"
    //   key="About"
    // />,
    // <Service
    //   dataSource={SolutionDataSource}
    //   isMobile={isMobile}
    //   key="Solution"
    // />,
    // <Feature1
    //   dataSource={FacilitiesDataSource}
    //   isMobile={isMobile}
    //   key="OurFacilities"
    // />,
    // <Feature1
    //   dataSource={FeatureAcademyDataSource}
    //   isMobile={isMobile}
    //   key="Academy"
    // />,
    // <Feature1
    //   dataSource={FeatureProblemDataSource}
    //   isMobile={isMobile}
    //   key="Problem"
    // />,
    // <Feature1
    //   dataSource={SustainabilityDataSource}
    //   isMobile={isMobile}
    //   key="Sustainability"
    // />,
    // <Feature1
    //   dataSource={OurServicesDataSource}
    //   isMobile={isMobile}
    //   key="OurServices"
    // />,
    // <About
    //   dataSource={ClubDataSource}
    //   isMobile={isMobile}
    //   video={TGPOutdoor}
    //   id="OurClubs"
    //   key="OurClubs"
    // />,
    // <Feature1
    //   dataSource={BaliFacilitiesDataSource}
    //   isMobile={isMobile}
    //   key="BaliFacilities"
    // />,
    // <Feature1
    //   dataSource={UpcomingLocationDataSource}
    //   isMobile={isMobile}
    //   key="UpcomingLocation"
    // />,
    // <Service
    //   dataSource={OurTeamDataSource}
    //   isMobile={isMobile}
    //   key="OurTeam"
    // />,
    <PreFooter
      isMobile={isMobile}
      key="Pre-Footer"
    />,
    <Footer
      dataSource={FooterDataSource}
      isMobile={isMobile}
      key="Footer"
    />,
  ]

  return (
    <div
      className="templates-wrapper"
    >
      {!show ? <Loader /> : children}
    </div>
  )
}

export default Home
