import { useEffect, useState } from 'react'
import { enquireScreen } from 'enquire-js'
import { BannerDataSource, CommunityDataSource, FooterDataSource, HeaderNavDataSource } from '../components/Home/data.source'
import Header from '../components/Home/Header'

import '../components/Home/less/antMotionStyle.less'
import Banner from '../components/Home/Banner'
import Footer from '../components/Home/Footer'
import Loader from '../components/Home/Loader'
import PreFooter from '../components/Home/PreFooter'

import WPTEspecial from '../assets/videos/WPT-Especial.mp4'
import About from '../components/Home/About'

let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const Community = () => {
  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)

  useEffect(() => {
    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 100)
    } else {
      isShown(true)
    }
  }, [isMobile, show])

  const children = [
    <Header
      dataSource={HeaderNavDataSource}
      isMobile={isMobile}
      key="Header"
    />,
    <Banner
      dataSource={BannerDataSource}
      isMobile={isMobile}
      video={WPTEspecial}
      key="Banner"
    />,
    <About
      dataSource={CommunityDataSource}
      isMobile={isMobile}
      key="Community"
    />,
    <PreFooter
      isMobile={isMobile}
      key="Pre-Footer"
    />,
    <Footer
      dataSource={FooterDataSource}
      isMobile={isMobile}
      key="Footer"
    />,
  ]

  return (
    <div
      className="templates-wrapper"
    >
      {!show ? <Loader /> : children}
    </div>
  )
}

export default Community
