/* eslint-disable jsx-a11y/iframe-has-title */
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IHomeComponent } from "./types"

const About : React.FC<IHomeComponent> = ({dataSource, isMobile, video, ...rest }) => {
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  }

  return (
    <div {...rest} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <h1 key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </h1>
          <div key="p" {...dataSource.content}>
            {dataSource.content.children}
          </div>
          {dataSource.content1 && (
            <div key="content-2nd" className="content1-content">
              {dataSource.content1.children}
            </div>
          )}
        </QueueAnim>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          {video ? (
            <video controls muted id="aboutVideo" width={'100%'}>
              <source src={video} type="video/mp4" />
            </video>
          ) : (
            <iframe width="100%" height="315"
              src="https://www.youtube.com/embed/73EsVXlWu9Q">
            </iframe>
          )}
          {dataSource.content2 && (
            <div key="content-3rd" className="content1-text">
              <div className="content1-content">
                {dataSource.content2.children}
              </div>
            </div>
          )}
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default About