import { createElement } from 'react'
import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IHomeComponent } from "./types"
import { isImg, isImportedImg } from './utils'
import WAIcon from '../Icons/Whatsapp'

const Footer: React.FC<IHomeComponent> = ({dataSource, isMobile, ...rest }) => {
  return (
    <>
      <div {...rest} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          {/* <TweenOne {...dataSource.links}>
            {dataSource.links.children.map((item: any, i: number) => {
              return (
                <a key={i.toString()} {...item}>
                  <img src={item.children} height="100%" alt="IQRA" />
                </a>
              );
            })}
          </TweenOne> */}
          <TweenOne
            animation={{ x: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyright}
          >
            {dataSource.copyright.children.map((item: any, i: number) =>
              createElement(
                item.name.indexOf('title') === 0 ? 'h2' : 'div',
                { key: i.toString(), ...item },
                typeof item.children === 'string' && (item.children.match(isImg) || item.children.match(isImportedImg))
                  ? createElement('img', {
                      src: item.children,
                      alt: 'img',
                    })
                  : item.children
              )
            )}
          </TweenOne>
        </OverPack>
      </div>
      <div className='wa-float-container'>
        <a href="https://api.whatsapp.com/send?phone=6282273138788&text=Halo," className="float" target="_blank" rel="noreferrer">
          <WAIcon />
        </a>
      </div>
    </>
  )
}

export default Footer