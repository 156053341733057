import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Store } from 'redux'
import { History } from 'history'
import Login from './screens/Login'
import { ApplicationState } from './stores'
import Home from './screens/Home'
import AboutUs from './screens/AboutUs'
import Locations from './screens/Locations'
import Community from './screens/Community'

interface IAppProps {
  store: Store<ApplicationState>
  history: History
}

const App: React.FC<IAppProps> = ({ store, history }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter basename={process.env.REACT_APP_PUBLIC_PATH}>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/login' component={Login}/>
            <Route path='/about' component={AboutUs}/>
            <Route path='/locations' component={Locations}/>
            <Route path='/community' component={Community}/>
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
