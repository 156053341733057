import * as React from "react"
import { SVGProps } from "react"

const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={21}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"mail"}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-4h24v24H-2z" />
      <path
        d="M18 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2Zm-.4 4.25-6.54 4.09c-.65.41-1.47.41-2.12 0L2.4 4.25a.85.85 0 1 1 .9-1.44L10 7l6.7-4.19a.85.85 0 1 1 .9 1.44Z"
        fill="#fff"
      />
    </g>
  </svg>
)

export default MailIcon
