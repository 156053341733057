import { useEffect, useState } from "react"
import { Redirect } from "react-router"

const Login = () => {
  const [loggedIn, isLoggedIn] = useState(false)

  useEffect(() => {
    const checkURLParams = () => {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      const jsessionid = params['JSESSIONID']
      
      if (jsessionid) {
        let expiredDate = new Date()
        expiredDate.setHours(expiredDate.getHours() + 1)
    
        localStorage.setItem('@expiredAt', expiredDate.toISOString())
        localStorage.setItem('@jsessionid', jsessionid)

        isLoggedIn(true)
      }
    }

    checkURLParams()
  }, [])

  if (loggedIn) {
    return <Redirect to="/dashboard" />
  }

  return (
    <></>
  )
}

export default Login