import React from 'react'
import QueueAnim from 'rc-queue-anim'
import { Col } from 'antd'
import { Element } from 'react-scroll'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { getChildrenToRender } from './utils'
import { IHomeComponent } from './types'

const Service : React.FC<IHomeComponent> = ({dataSource, isMobile, ...rest }) => {
  const {
    wrapper,
    titleWrapper,
    page,
    OverPack: overPackData,
    childWrapper,
  } = dataSource

  return (
    <Element {...rest} {...wrapper}>
      <div {...page}>
        <div {...titleWrapper}>
          {titleWrapper.children.map(getChildrenToRender)}
        </div>
        <OverPack {...overPackData}>
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            componentProps={childWrapper}
          >
            {childWrapper.children.map((block: any, i: number) => {
              const { children: item, ...blockProps } = block
              return (
                <Col key={i.toString()} {...blockProps}>
                  <div {...item}>
                    {item.children.map(getChildrenToRender)}
                  </div>
                </Col>
              )
            })}
          </QueueAnim>
        </OverPack>
      </div>
    </Element>
  )
}

export default Service
